<template>
    <div>
        <Header :customstyle="{ background: '#0C1913' }">
            <template slot="title">团队</template>
        </Header>
        <div class="content">
            <div class="statistical">
                <div class="title">
                    团队统计
                </div>
                <div class="data offset">
                    <div>
                        <div class="num">{{statistical.directUser}}</div>
                        <div class="txt">直推人数</div>
                    </div>
                    <div>
                        <div class="num">{{statistical.teamUser}}</div>
                        <div class="txt">团队人数</div>
                    </div>
                    <div>
                        <div class="num">{{statistical.teamActive}}</div>
                        <div class="txt">团队活跃</div>
                    </div>
                </div>
                <div class="data offset">
                    <div>
                        <div class="num">{{(parseFloat(statistical.hold_balance)).toFixed(3)}}</div>
                        <div class="txt">LP持有</div>
                    </div>
                    <div>
                        <div class="num">{{(parseFloat(statistical.recommend_balance)).toFixed(3)}}</div>
                        <div class="txt">LP推广</div>
                    </div>
                    <div>
                        <div class="num">{{(parseFloat(statistical.team_hold_balance)).toFixed(3)}}</div>
                        <div class="txt">团队LP</div>
                    </div>
                </div>
            </div>
            <div class="guard">
                <div class="title">
                    团队详情
                </div>
                <div class="data">
                    <div v-for="item in starInfo" :key="item.txt" class="startbox">
                        <div class="num">{{item.num}}</div>
                        <div class="txt">{{item.txt}}</div>
                    </div>
                </div>
            </div>
            <div class="recommended">
                <div class="titile">
                    我的推荐
                </div>
                <div class="datalist">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多记录了"
                    @load="onLoad"
                    >
                        <div class="cell" v-for="(record, index) in recordList" :key="index">
                            <div class="detail">
                                <div class="txt">{{record.address.substr(0,3)+'....'+record.address.substr(-3)}}
                                    &nbsp;&nbsp;&nbsp;
                                    个人活跃度：{{record.teamActive}}
                                    </div>
                                <div class="txt">
                                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    个人LP持有：{{record.hold_balance?parseFloat(record.hold_balance).toFixed(3):0}}
                                </div>
                                <div class="time">时间：{{record.timestamp}}</div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../../components/header.vue';
import {teamInfo, inviteRecord} from '@/request/api'
export default {
    components: { Header },
    data () {
        return {
            refreshing: false,
            loading: false,
            finished: false,
            recordList: [
            ],
            statistical: {
                directUser:0,
                teamUser: 0,
                teamActive: 0,
                hold_balance:0,
                recommend_balance: 0,
                team_hold_balance: 0
            },
            starInfo: [],
            currentPage: 1,
            address:sessionStorage.getItem("address"),
        }
    },
    activated () {
        if(this.address&& sessionStorage.getItem(this.address)){
            this.getTeamInfo()
        }
    },
    methods: {
        getTeamInfo() {
            this.starInfo=[];
            const params = {
                address:this.address
            }
            teamInfo(params).then((res)=> {
                this.statistical.directUser = res.data.directUser
                this.statistical.teamUser = res.data.teamUser
                this.statistical.teamActive = res.data.teamActive
                this.statistical.hold_balance = res.data.hold_balance
                this.statistical.recommend_balance = res.data.recommend_balance
                this.statistical.team_hold_balance = res.data.team_hold_balance
                this.starInfo.push({
                    txt:'环保卫士',
                    num: res.data.vip1Num
                })
                this.starInfo.push({
                    txt:'环保达人',
                    num: res.data.vip2Num
                })
                this.starInfo.push({
                    txt:'环保大使',
                    num: res.data.vip3Num
                })
                this.starInfo.push({
                    txt:'环保英雄',
                    num: res.data.vip4Num
                })
                this.starInfo.push({
                    txt:'联盟合伙人',
                    num: res.data.partner1Num
                })
                this.starInfo.push({
                    txt:'初级合伙人',
                    num: res.data.partner2Num
                })
            })
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.currentPage = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        onLoad() {
            if(this.address&& sessionStorage.getItem(this.address)){
                this.getDataList();
                this.currentPage++;
            }else{
                this.loading = false;
            }
        },
        getDataList() {
            const params = {
                address:this.address,
                page:this.currentPage,
                limit:"20"
            }
            inviteRecord(params).then((res)=>{
                if (this.refreshing) {
                    this.recordList = [];
                    this.refreshing = false;
                }
                this.loading = false;
                this.recordList = [...this.recordList, ...res.data.list];
                // this.recordList = res.data.list
                if (res.data.list.length < 20) {
                    this.finished = true;
                }
                console.log(  this.recordList)
            })
            // setTimeout(() => {
                // if (this.refreshing) {
                //     // this.listdata = [];
                //     this.refreshing = false;
                // }
            //     // 加载状态结束
            //     this.loading = false;

            //     // 数据全部加载完成
            //     this.finished = true;
            // }, 1000);
        }
    }
}
</script>

<style scoped lang="scss">
.offset {
    margin-top: 32px;
}
.content {
    padding: 10px 32px;
    background: #0C1913;
    min-height: 100vh;
    .statistical{
        padding: 24px;
        width: 686px;
        height: 380px;
        border-radius: 24px 24px 0 0 ;
        opacity: 1;
        background: linear-gradient(180deg, rgba(25,67,21,1) 0%, rgba(16,43,29,1) 20%, rgba(16,43,29,1) 100%);
    }
    .guard {
        padding: 24px;
        position: relative;
        top: -30px;
        width: 686px;
        height: 364px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(41,51,45,1);
    }
    .title {
        width: 128px;
        height: 44px;
        opacity: 1;
        color: rgba(214,255,227,1);
        font-size: 32px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 44px;
    }
    .data {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .startbox {
            margin-top: 32px;
        }
        .num {
            width: 207px;
            opacity: 1;
            color: rgba(214,255,227,1);
            font-size: 44px;
            font-weight: 500;
            font-family: "DIN";
            text-align: center;
        }
        .txt {
            width: 207px;
            opacity: 1;
            color: rgba(182,217,195,1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 36px;
        }
    }
    .recommended {
        width: 686px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32,40,35,1);
        padding: 24px;
        .titile {
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: left;
        }
         .datalist {
            .date {
                margin-top:32px;
                color: rgba(139,166,149,1);
                font-weight: 400;
                text-align: left;
                line-height: 52px;
                font-size: 40px;
            }
            .detail {
                margin-top: 16px;
                .txt {
                    color: rgba(214,255,227,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                }
                .time {
                    color: rgba(139,166,149,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: left;
                    line-height: 40px;
                }
            }
        }
    }
}
</style>